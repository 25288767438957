import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { metadataUrlFormKeys } from '../../constants/metadata-form-keys';
import { MetadataUrlFormFields } from '../../interfaces/metadata-form-fields';
import { MetadataUrlStep } from '../../metadata-step';

const Component: FC = () => (
  <MetadataUrlStep
    idpEntityIdPlaceholder="https://mykeycloak.com/123-abc/"
    idpSsoUrlPlaceholder="http://mykeycloak.com/auth/realms/myrealm/protocol/saml"
    metadataUrlPlaceholder="https://mykeycloak.com/auth/realms/myrealm/protocol/saml/descriptor"
  >
    <Text>
      Select "Realm Settings" in the left sidebar navigation menu, and copy the
      "SAML 2.0 Identity Provider Metadata" link on the General page, and input
      it below.
    </Text>

    <Img
      className="my-8"
      height={1360}
      src="/images/166ae678-b776-470e-bf66-62bf202207e1.png"
      width={2272}
    />
  </MetadataUrlStep>
);

export const uploadIdpMetadata: StepData<MetadataUrlFormFields> = {
  title: 'Upload IdP Metadata',
  component: Component,
  formKeys: metadataUrlFormKeys,
};
