import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { CopyInput } from '../../../../components/inputs';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { useSsoStore } from '../../sso-store-provider';

const Component: FC = () => {
  const { connection } = useSsoStore();
  return (
    <StepContent confirmLabel="I've created a SAML Client">
      <Text>
        Log in to your Keycloak Admin Console, and navigate to the Realm you
        want to set up the SAML client in. Select "Clients" from the side menu
        and then select "Create".
      </Text>

      <Img
        className="my-8"
        height={1352}
        src="/images/ab144a33-174d-4699-83ad-fa5e59b9b31d.png"
        width={2158}
      />

      <Text>
        On the Create Client setup step, select `SAML` as the "Client type".
        Insert the Client ID in the “Client ID” input field. Set a name for your
        Client. Click "Save".
      </Text>

      <CopyInput
        id="saml-entity-id"
        label="Copy this Client ID"
        value={connection.saml_entity_id}
      />

      <Img
        className="my-8"
        height={1352}
        src="/images/9365694a-b125-47e0-8d2e-1e49425e157b.png"
        width={2158}
      />
    </StepContent>
  );
};

export const createSamlClient: StepData = {
  title: 'Create SAML Client',
  component: Component,
};
