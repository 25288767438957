import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: FC = () => (
  <StepContent confirmLabel="I've mapped the user attributes.">
    <Text>
      Click the “Client scopes” top menu option and click into your Client..
    </Text>

    <Img
      className="my-8"
      height={1352}
      src="/images/cc6c1ce7-229f-4d1e-90b2-ecf060649018.png"
      width={2252}
    />

    <Text>Click "Configure a new mapper".</Text>

    <Img
      className="my-8"
      height={1352}
      src="/images/3ed96fbb-d089-49ed-979b-2b360a498db0.png"
      width={2252}
    />

    <Text>Then select "User Property".</Text>

    <Img
      className="my-8"
      height={1352}
      src="/images/6f82859d-a433-4e3c-bde4-10b4700e5ba0.png"
      width={2252}
    />

    <Text>
      You'll need to create a "User Property" mapper for the following four
      attributes: "id", "email", "firstName", and "lastName".
    </Text>

    <Text>This is an example of how to fill out the fields for "id":</Text>

    <Img
      className="my-8"
      height={1286}
      src="/images/1d966f7c-358b-46a6-b8d5-cbcb6f80c192.png"
      width={2014}
    />

    <Text>
      Also do this for the "email", "firstName", and "lastName" attributes:
    </Text>

    <Img
      className="my-8"
      height={1286}
      src="/images/7f753d8c-a7f3-4250-a772-0fb31bddfb03.png"
      width={2014}
    />

    <Img
      className="my-8"
      height={1286}
      src="/images/d037d45f-9d80-4594-b0d2-4ec65dd76d96.png"
      width={2014}
    />

    <Img
      className="my-8"
      height={1286}
      src="/images/0df763a9-7ca9-4bfb-870b-472bc3ef4b8b.png"
      width={2014}
    />
  </StepContent>
);

export const mapUserAttributes: StepData = {
  title: 'Map User Attributes',
  component: Component,
};
